<script>
import {formatWeightWithUnits} from "../store/utils";

export default {
  name: "ComponentAuditList",
  methods: {
    formatWeightWithUnits,
    isShort(part) {
      return (part.totalAmountMeasured > 0 && part.totalAmount > part.totalAmountMeasured);
    },
    isShortCount(part) {
      return part.isShortCount;
    },
    shortFilter(part) {
      return this.isShort(part) || this.isShortCount(part);
    }
  },
  props: {
    meals: {type: Array, required: true},
    hideCompleteCount: {type: Boolean, default: null, required: false},
    onlyShorts: {type: Boolean, default: false, required: false},
    expandMissing: {type: Boolean, default: false, required: false},
    hideSummaryButtons: {type: Boolean, default: false, required: false},
    hideShowButton: {type: Boolean, default: false, required: false},
    showSkipped: {type: Boolean, default: false, required: false},
  },
  data() {
    return {
      showMissingParts: {}
    }
  }
}
</script>

<template>
  <div>
    <div>
      <div v-for="(meal,i) of meals.filter(({key})=>key!=='CC')"
           v-bind:key="i">
        <span v-if="!hideCompleteCount">
        {{ meal.itemsComplete }} of {{ meal.itemCount }} -
        </span>
        <span style="font-weight: bolder" v-if="meal.priority">(P{{ meal.priority }})</span>
        {{ meal.rack }} {{ meal.key }} {{ meal.name }}
        <template v-if="!hideSummaryButtons">
          <v-btn color="secondary" x-small
                 v-if="meal.partsRemaining.length>0"
                 @click="$set(showMissingParts,meal.mealId,!showMissingParts[meal.mealId])">
            <span v-if="meal.isMainMissing && meal.isAllergyMissing">{{ meal.itemsLeft }} Both</span>
            <span v-if="meal.isMainMissing && !meal.isAllergyMissing">{{ meal.isMainMissing }} Main</span>
            <span v-if="!meal.isMainMissing && meal.isAllergyMissing">{{ meal.isAllergyMissing }} Allergy</span>
          </v-btn>
          <v-chip @click="$set(showMissingParts,meal.mealId,!showMissingParts[meal.mealId])"
                  class="ml-4" x-small color="red" outlined
                  v-if="meal.partsRemaining.some(isShort)">
            SHORT
          </v-chip>
          <v-chip @click="$set(showMissingParts,meal.mealId,!showMissingParts[meal.mealId])"
                  class="ml-4" x-small color="red" outlined
                  v-if="meal.partsRemaining.some(isShortCount)">
            SHORT CT
          </v-chip>
        </template>

        <ol v-if="expandMissing? !showMissingParts[meal.mealId] : showMissingParts[meal.mealId]">
          <li v-for="part of onlyShorts ? meal.partsRemaining.filter(shortFilter) : meal.partsRemaining"
              v-bind:key="part.task.title"
              :style="isShort(part)||isShortCount(part)?'color: red':''"
          >
            <span style="font-weight: bolder" v-if="part.task.source.priority">
              P{{ part.task.source.priority }}
            </span>
            {{ part.task.line === 'M' ? 'M' : 'A' }}{{ part.task.source.rack }}
            {{ formatWeightWithUnits(part.totalAmount) }}
            <span style="font-weight: bolder"
                  v-if="part.task.source.batch"> {{ part.task.source.batch }} </span>
            {{ part.task.source.line }}
            |
            <span v-if="part.task.source.line==='S'">Sub for </span>
            {{ part.task.description }} -
            <template v-if="part.hasWeightMeasurements">
              {{
                formatWeightWithUnits(part.totalAmount - part.totalAmountMeasured)
              }} short
            </template>
            <template v-if="part.hasCount">
              short
              <span v-for="(count,size) of part.shortCounts" v-bind:key="size">
                {{ `${size[0]}`.toUpperCase() }}:{{ count }}CT
              </span>
            </template>
            <v-btn v-if="!hideShowButton" outlined x-small @click="$emit('show',part.task.title)">Show</v-btn>

            <template v-if="part.overAmounts && part.overAmounts.length>0">
              <br/>
              <span style="color:green">but these meals had extra</span>
              <ol style="color:green">
                <li v-for="overPart of part.overAmounts" v-bind:key="`${part.task.title}-${overPart.task.title}`">
                  {{ overPart.task.source.line === 'M' ? 'M' : 'A' }}{{ overPart.task.source.rack }}
                  {{ overPart.task.source.key }}
                  {{ overPart.task.source.line }}
                  {{ overPart.task.source.mealName }}
                  - {{ overPart.title }}
                  <template v-if="overPart.totalAmountMeasured>0">
                    {{ formatWeightWithUnits(overPart.totalAmountMeasured - overPart.totalAmount) }} over
                  </template>
                  <template v-if="overPart.hasCount">
                    <span v-for="(count,size) of overPart.overCounts" v-bind:key="size">
                      {{ `${size[0]}`.toUpperCase() }}:{{ -count }}CT
                    </span>
                  </template>
                  <v-btn v-if="!hideShowButton" outlined x-small @click="$emit('show',overPart.task.title)">Show</v-btn>
                </li>
              </ol>
            </template>
          </li>

        </ol>


        <ul v-if="showSkipped">
          <li v-for="part of  meal.partsSkipped" v-bind:key="part.task.title"
              :style="isShort(part)||isShortCount(part)?'color: red':''"
          >
                        <span style="font-weight: bolder" v-if="part.task.source.priority">
              P{{ part.task.source.priority }}
            </span>
            {{ part.task.line === 'M' ? 'M' : 'A' }}{{ part.task.source.rack }}
            {{ formatWeightWithUnits(part.totalAmount) }}
            <span style="font-weight: bolder"
                  v-if="part.task.source.batch"> {{ part.task.source.batch }} </span>
            {{ part.task.source.line }}
            |
            <span v-if="part.task.source.line==='S'">Sub for </span>
            {{ part.task.description }} -
            <template v-if="part.hasWeightMeasurements">
              {{
                formatWeightWithUnits(part.totalAmount - part.totalAmountMeasured)
              }} short
            </template>
            <template v-if="part.hasCount">
              short
              <span v-for="(count,size) of part.shortCounts" v-bind:key="size">
                {{ `${size[0]}`.toUpperCase() }}:{{ count }}CT
              </span>
            </template>
            <v-btn v-if="!hideShowButton" outlined x-small @click="$emit('show',part.task.title)">Show</v-btn>
            <template v-if="part.overAmounts && part.overAmounts.length>0">
              <ul style="color:green">
                <li v-for="overPart of part.overAmounts" v-bind:key="`${part.task.title}-${overPart.task.title}`">
                  {{ overPart.task.source.line === 'M' ? 'M' : 'A' }}{{ overPart.task.source.rack }}
                  {{ overPart.task.source.key }}
                  {{ overPart.task.source.line }}
                  {{ overPart.task.source.mealName }}
                  - {{ overPart.title }}
                  <template v-if="overPart.totalAmountMeasured>0">
                    {{ formatWeightWithUnits(overPart.totalAmountMeasured - overPart.totalAmount) }} over
                  </template>
                  <template v-if="overPart.hasCount">
                    <span v-for="(count,size) of overPart.overCounts" v-bind:key="size">
                      {{ `${size[0]}`.toUpperCase() }}:{{ -count }}CT
                    </span>
                  </template>
                  <v-btn v-if="!hideShowButton" outlined x-small @click="$emit('show',overPart.task.title)">Show</v-btn>
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>