<template>
  <v-container fluid>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-alert v-if="isDisconnected && loadingTasks===0" type="warning">
      Disconnected from Server. Tasks will not update!
    </v-alert>
    <v-card-actions>
      <template v-if="!scannerMode">
        <v-text-field
          class="d-print-none"
          v-model="searchInput"
          append-icon="mdi-magnify"
          :label="scannerMode?'Scanner Mode is Enabled':'Search'"
          single-line
          hide-details
          clearable
          :disabled="scannerMode"
        />
        <v-spacer/>
      </template>
      <v-text-field :value="datesFormatted"
                    persistent-hint
                    hint="Meal Dates"
                    label="Select dates"
                    single-line
                    readonly
                    @click="datePickerDate=dates[0]; showDatePicker=true"
                    append-icon="mdi-calendar"
      />
      <v-dialog
        v-model="showDatePicker"
        ref="dialog"
        width="290px"
        persistent
      >
        <v-date-picker
          v-model="datePickerDate"
          no-title
          :allowed-dates="allowedDates"
          @close="closeDatePicker"
        ></v-date-picker>
        <v-btn @click="closeDatePicker">Close</v-btn>
      </v-dialog>
      <v-spacer/>
      <v-btn-toggle v-model="teamFilter" multiple>
        <v-btn
          v-for="(team,i) of teams" v-bind:key="i"
          :value="team"
        >{{ team }} ({{ teamCount[team] }})
        </v-btn>
      </v-btn-toggle>
      <template v-if="!shortSummary">
        <v-spacer/>
        <v-checkbox
          v-model="scannerMode"
          label="Scanner"
          class="ml-1"
        />
      </template>
    </v-card-actions>
    <v-card-title v-if="teamFilter.length>0">
      <v-spacer/>
      Team filter is active. Showing only {{ teamFilter.join(', ') }}.
      <v-spacer/>
      <v-btn @click="teamFilter=[]" outlined>Clear filter</v-btn>
      <v-spacer/>
    </v-card-title>
    <v-card-text class="my-0 py-0">
      <v-alert type="info" v-if="!loading" outlined>
        Items to weigh: {{ tasksFiltered.length }}
        Items weighed: {{ tasksWithMeasurement.length }}
        Items remaining: {{ tasksFiltered.length - tasksWithMeasurement.length }}
      </v-alert>
    </v-card-text>
    <v-card-actions v-if="!scannerMode">
      <v-btn outlined @click="csvExport(dataAsTable,exportFilename())">Download CSV</v-btn>
    </v-card-actions>
    <!--    <pre>{{ (dataAsTable[0] || {}) }}</pre>-->
    <template v-if="isAuditMode">
      <v-card-text v-if="!loading && tasks.length===0 && shortSummary">
        <v-row>
          <v-col>
            <h2>Known Main Shorts</h2>
            <span v-if="mealsWithMainShort.length===0">None</span>
            <ComponentAuditList
              :meals="mealsWithMainShort"
              hide-complete-count
              only-shorts
              expand-missing
              hide-summary-buttons
              v-on:show="searchInput=$event"
            />
          </v-col>
          <v-col>
            <!--          <h2>Sub - Ready To Plate - {{ (((mealsSubReady.length / mealsWithSub.length)) * 100).toFixed(0) }}%</h2>-->
            <!--          Sub Meals Completed:-->
            <!--          <span v-if="mealsSubReady.length===0">None</span>-->
            <!--          <span v-if="mealsSubReady.length>0">{{ mealsReady.length }} complete of {{ mealsWithSub.length }}</span>-->
            <h2>Known Sub Shorts</h2>
            <span v-if="mealsWithSubShort.length===0">None</span>
            <ComponentAuditList
              :meals="mealsWithSubShort"
              hide-complete-count
              only-shorts
              expand-missing
              hide-summary-buttons
              v-on:show="searchInput=$event"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Main Line Meals with Skipped Components</h2>
            <ComponentAuditList
              :meals="mealsWithMainSkipped"
              hide-complete-count
              only-shorts
              expand-missing
              hide-summary-buttons
              v-on:show="searchInput=$event"
              show-skipped
            />
          </v-col>
          <v-col>
            <h2>Subs with Skipped Components</h2>
            <ComponentAuditList
              :meals="mealsWithSubSkipped"
              hide-complete-count
              only-shorts
              expand-missing
              hide-summary-buttons
              v-on:show="searchInput=$event"
              show-skipped
            />
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-text v-if="!loading && tasks.length===0 && !shortSummary">
        <v-row v-if="tasksFiltered.length>0">
          <v-col>
            <h2>Main Ready To Plate - {{ (((mealsReady.length / mealCompletionStatus.length)) * 100).toFixed(0) }}%</h2>
            Main Line:
            <span v-if="mealsReady.length===0">None</span>
            <span v-if="mealsReady.length>0">{{ mealsReady.length }} complete</span>
            <ComponentAuditList
              :meals="mealsReady"
              hide-complete-count
              v-on:show="searchInput=$event"
            />
          </v-col>
          <v-col cols="8">
            <h2>Not Ready To Plate</h2>
            {{ mealsNearlyReady.length - mealsNotStarted.length }} partially complete, {{ mealsNotStarted.length }} not
            started
            <ComponentAuditList
              v-on:show="searchInput=$event"
              :meals="mealsNearlyReady"/>
          </v-col>
        </v-row>
      </v-card-text>
      <template v-if="!scannerMode && tasks.length>1">
        <!--        <v-data-table-->
        <!--          :headers="Object.keys(dataAsTable[0]||{}).map(k => ({value: k, text: k}))"-->
        <!--          :items="dataAsTable"-->
        <!--        />-->
        <v-card v-for="task of tasks" v-bind:key="task.id" class="mb-5">
          <v-card-text @click="searchInput=task.title">
            {{ task.title }} {{ task.source.key }}
            {{ task.description }} {{ formatWeightWithUnits(task.source.totalAmount) }}
            {{ task.reference }}
          </v-card-text>
        </v-card>
      </template>
    </template>
    <template v-if="isWasteMode">
      <template v-if="scannerMode">
        <v-card-text v-if="!loading && tasks.length===0">
          <v-card-title>Recorded Waste</v-card-title>
          <v-card v-for="task of tasksWithMeasurement" v-bind:key="task.id" class="mb-5">
            <v-card-text @click="searchInput=task.title">
              {{ formatCurrency(getTotalNet(task.source.measurements) * task.source.costPerGram) }}
              <v-chip outlined>Waste {{ formatWeightWithUnits(getTotalNet(task.source.measurements)) }}</v-chip>
              <strong>{{ task.description }}</strong>
              {{ task.title }} {{ task.source.key }}
            </v-card-text>
          </v-card>
        </v-card-text>
      </template>
      <template v-if="!scannerMode && tasks.length>1">
        <v-card v-for="task of tasks" v-bind:key="task.id" class="mb-5">
          <v-card-text @click="searchInput=task.title">
            <v-chip outlined>Required {{ formatWeightWithUnits(task.source.totalAmount) }}</v-chip>
            <strong>{{ task.description }}</strong>
            {{ task.title }} {{ task.source.key }}
            {{ task.reference }}
          </v-card-text>
        </v-card>
      </template>
    </template>
    <template v-if="scannerMode">
    </template>
    <template v-if="task">
      <v-card-title>
        {{ task.title }} {{ task.source.key }}
        <template v-if="task.source.measurements && task.source.measurements.length>0">
          <v-spacer/>
          <template v-if="isAuditMode">
            <v-chip color="red" outlined v-if="isShortWeightTask(task.source)">
              SHORT {{ formatWeightWithUnits(Math.abs(getDifference(task.source))) }}
            </v-chip>
            <template v-if="isShortCountTask(task.source)">
              <v-chip class="mr-2" color="red" outlined v-for="(count,size) of getShortCounts(task.source)"
                      v-bind:key="size">
                SHORT {{ size[0].toUpperCase() }}:{{ count }}CT
              </v-chip>
            </template>
            <v-chip color="blue" outlined v-if="isOverWeightTask(task.source)">
              OVER {{ formatWeightWithUnits(Math.abs(getDifference(task.source))) }}
            </v-chip>
            <v-chip color="green" outlined v-if="isWeightOkTask(task.source) && !isShortCountTask(task.source)">OK
            </v-chip>
            <v-spacer/>
          </template>
          <v-icon color="green">mdi-scale</v-icon>
          {{ formatWeightWithUnits(sumNetWeight(task.source.measurements)) }}
        </template>
        <v-spacer/>
        <template v-if="task.source.line">
          <v-spacer/>
          <div v-if="task.source.line==='M'" class="headline text-center">MAIN</div>
          <div v-if="task.source.line==='S'" class="box text-center">S</div>
          <div v-if="task.source.line==='AL'" class="circle-smaller text-center">AL</div>
        </template>
        <v-spacer/>
        {{ formatWeightWithUnits(task.source.totalAmount) }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span v-if="task.source.line==='S'">Substitute for</span>
            {{ formatWeightWithUnits(task.source.totalAmount) }} of {{ task.description }}
            <br/>
            used in {{ task.source.mealName }} <strong>{{ task.source.key }} </strong>
          </v-col>
          <v-col class="text-right">
            Portion size {{ formatWeightWithUnits(task.source.portionSize) }}

            <!--              {{-->
            <!--                Object.entries(task.source.count).filter(([k, v]) => v > 0).map(([k, v]) => `${k}: ${v}CT`).join(' ')-->
            <!--              }}-->
            <br/>
            <v-row dense>
              <v-col/>
              <v-col v-for="size of sortBySize(Object.keys(task.source.count))" v-bind:key="size">
                {{ size }}: {{ task.source.count[size] }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col/>
              <v-col v-for="size of sortBySize(Object.keys(task.source.count))" v-bind:key="size">
                <v-chip
                  v-if="getCounts(task.source).difference[size]>0 && getCounts(task.source).difference[size]<task.source.count[size]"
                  outlined color="red">
                  short {{ Math.max(getCounts(task.source).difference[size], 0) }}
                </v-chip>
                <v-chip
                  v-if="getCounts(task.source).difference[size]<0 && getCounts(task.source).difference[size]<task.source.count[size]"
                  outlined color="green">
                  over {{ Math.abs(getCounts(task.source).difference[size]) }}
                </v-chip>
              </v-col>
            </v-row>

          </v-col>
        </v-row>

      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col></v-col>
          <v-col>
            <v-btn-toggle rounded v-model="containerFilter">
              <v-btn small value="\*">Popular (*)</v-btn>
              <v-btn small value="hotel pan">Hotel Pan</v-btn>
              <v-btn small value="^(?!.*hotel pan).*">Other Container</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col>
            <router-link :to="{name:'Container'}">edit containers</router-link>
          </v-col>
        </v-row>
        <v-chip-group
          column
          active-class="deep-purple--text darken-3 text--accent-4"
        >
          <v-chip
            v-for="container of selectedContainers" v-bind:key="container.id"
            outlined
            @click="addContainerMeasurement(task.source.measurements,container.id)"
          >
            {{ container.name }}
          </v-chip>
        </v-chip-group>
        <v-data-table
          :headers="containerHeaders(task)"
          :items="task.source.measurements"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.container_id="{item}">
            {{ getContainer(item.container_id).name }} ({{
              formatWeightWithUnits(getContainer(item.container_id).weight)
            }})
          </template>
          <template v-slot:item.action="{item,index}">
            <v-icon @click="removeContainerMeasurement(task.source.measurements,index)" class="ml-2" color="red">
              mdi-minus-circle
            </v-icon>
          </template>
          <template v-slot:item.counts.medium="{item}">
            <v-text-field
              style="max-width:60px"
              dense
              v-model="item.counts.medium"
              reverse
              type="number"
              min="0"
            />
          </template>
          <template v-slot:item.counts.small="{item}">
            <v-text-field
              style="max-width:60px"
              dense
              v-model="item.counts.small"
              reverse
              type="number"
              min="0"
            />
          </template>
          <template v-slot:item.counts.large="{item}">
            <v-text-field
              style="max-width:60px"
              dense
              v-model="item.counts.large"
              reverse
              type="number"
              min="0"
            />
          </template>
          <template v-slot:item.total_weight="{item}">
            <v-text-field
              style="max-width:150px"
              dense
              v-model="item.total_weight"
              prefix="g"
              reverse
              type="number"
              min="0"
              :rules="weightRules(item)"
            />
          </template>
          <template v-slot:item.net_weight="{item}">
            <v-text-field
              disabled
              style="max-width:150px"
              dense
              type="number"
              min="0"
              prefix="g"
              reverse
              :value="getNetWeight(item.container_id,item.total_weight)"
            />
          </template>

        </v-data-table>
        <!--        <pre>{{task.source}}</pre>-->
        <!--        {{ {percentOver: percentOver(task.source)} }}-->
        <v-alert type="warning" v-if="percentOver(task.source)>10 && (getDifference(task.source)>1000)">
          WARNING: The recorded weight is {{ percentOver(task.source) }}% over the <b>total</b> target weight!
          Are you sure this is correct?
          <br/>
          You entered: {{ formatWeightWithUnits(sumNetWeight(task.source.measurements)) }} vs total component target
          {{ formatWeightWithUnits(task.source.totalAmount) }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="searchInput=''">Close</v-btn>
        <v-spacer/>
        <v-btn color="primary" :disabled="!hasEdit(task)" @click="save(task)">Save</v-btn>
        <template v-if="showSkip">
          <v-spacer/>
          <v-btn v-if="task.source.skipped" color="error" @click="setSkipped(task, false)">Do Not Ignore</v-btn>
          <v-btn v-if="!task.source.skipped" color="warning" @click="setSkipped(task, true)">Skip/Ignore</v-btn>
        </template>
      </v-card-actions>
      <!--        <pre>{{task}}</pre>-->
    </template>
  </v-container>
</template>
<style scoped>
.box {
  border: 2px solid black;
  height: 40px;
  width: 40px;
  padding: 0;
  font-size: x-large;
}

.circle {
  padding: 0;
  border: 4px solid black;
  height: 70px;
  border-radius: 50%;
  width: 70px;
  font-size: 50px;
  line-height: 60px;
  font-weight: bolder;
}

.circle-smaller {
  padding: 0;
  border: 3px dashed black;
  height: 50px;
  border-radius: 50%;
  width: 50px;
  font-size: 30px;
  line-height: 45px;
}

</style>


<script>
import {csvExport, formatCurrency, formatWeightWithUnits, getDatesInRange, getProductionDays} from "@/store/utils";
import diff from 'microdiff';
import urlState from "@/router/urlState";
import {mapActions, mapState} from "vuex";
import api from "@/api";
import moment from "moment/moment";
import ComponentAuditList from "@/components/ComponentAuditList.vue";

function sizeSort(a, b) {
  const map = {small: 1, medium: 2, large: 3};
  return (map[a] || 4) - (map[b] || 4);
}

export default {
  name: "ComponentAudit",
  components: {ComponentAuditList},
  mixins: [urlState],
  props: {
    event: {type: String, required: true},
    shortSummary: {type: Boolean, default: false, required: false}
  },
  data() {
    return {
      loading: null,
      loadingTasks: 0,
      currentDates: null,
      team: undefined,
      tasksUnfiltered: [],
      dates: [],
      isDisconnected: null,
      showWeighDialog: null,
      buffer: '',
      searchInput: '',
      scannerMode: true,
      containerFilter: '\\*',  //'hotel pan'
      showDatePicker: null,
      datePickerDate: null,
      team_id_promise: null,
      complete: {},
      notComplete: {},
      teamFilter: []
    }
  },
  destroyed() {
    this.stopListener();
  },
  mounted() {
    if (this.isAuditMode) {
      this.team = 'audit';
    } else if (this.isWasteMode) {
      this.team = 'waste';
    } else {
      console.error('not sure what team to use for event ' + this.event);
    }
    this.team_id_promise = api.get(`v2/task/team/${this.team}`)
      .then(({data}) => {
        // console.log('retrieved team', data);
        return data.id;
      });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'searchInput', urlParam: 'search', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'teamFilter', urlParam: 'team', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v].filter(v => !!v) // filter out null or blank
    });
    this.syncToUrl({
      param: 'scannerMode', urlParam: 'scan', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.$socket.on('connect', () => this.isDisconnected = false);
    this.$socket.on('disconnect', () => this.isDisconnected = true);
    this.$socket.on('task:updated', (task) => this.taskUpdated(task));
    return Promise.all([
      this.fetchTasks(getDatesInRange(this.dateFrom, this.dateTo)),
      this.fetchContainers(),
    ])
  },
  watch: {
    dates(v, old) {
      console.log('dates', v, old);
      return this.fetchTasks(getDatesInRange(this.dateFrom, this.dateTo));
    },
    isDisconnected(val, old) {
      if (!val && old) {
        this.loadingTasks = 0;
        console.log('reconnected, refetch tasks', this.loadingTasks);
        this.fetchTasks(getDatesInRange(this.dateFrom, this.dateTo));
      } else {
        console.log('isDisconncted skip, ', {val, old});
      }
    },
    tasks(v) {
      if (v.length === 1) {
        this.stopListener();
      } else {
        this.startListener();
      }
    }
  },
  methods: {
    formatCurrency,
    csvExport,
    ...mapActions(['fetchContainers']),
    formatWeightWithUnits,
    fetchData() {
      this.fetchTasks(getDatesInRange(this.dateFrom, this.dateTo));
    },
    // has side effect
    fixNumbers(measurements) {
      // text input saves number, so need to replace with Number
      for (const m of measurements) {
        m.total_weight = m.total_weight !== undefined ? Number(m.total_weight) : '';
        if (m.container) {
          m.net_weight = m.total_weight - m.container.weight;
        } else {
          console.log('no container');
        }
      }
      return measurements;
    },
    allowedDates(val) {
      const notAllowed = {
        0: true,
        // 2: true,
        // 4: true
      };

      return !notAllowed[moment(val).day()];
    },
    async fetchTasks(dates) {
      if (!this.team) {
        console.error('no team selected');
        return
      }
      if (dates.length === 0) {
        console.log('no dates, cannot fetch tasks yet');
        return;
      }
      this.loading = true;
      this.loadingTasks++;

      this.currentDates = [this.dateFrom, this.dateTo];
      console.log('tasks:fetch', this.loadingTasks, {dates, team: this.team});
      this.tasksUnfiltered = [];
      return new Promise(resolve => {
        this.$socket.emit('tasks:fetch',
          {dates, team: this.team, includeCosts: true, forceRegenerate: true},
          (result) => {
            let {error, tasks} = result;
            if (error) {
              alert(`fetch tasks ${dates} failed: ${error}`);
            } else {
              tasks = tasks.sort((a, b) => a.id - b.id);
              console.log(`tasks:fetch ${this.team} - ${dates} result`, tasks.length);
              // console.log(`tasks:fetch ${this.team} - ${dates} result`, tasks);
              const taskMap = {};
              this.tasksUnfiltered = [];
              tasks.forEach(task => {
                const {description} = task;
                if (/garnish\)/i.test(description)) {
                  // console.log('skipping garnish', description);
                  return;
                }
                this.resetOriginalValues(task);
                if (taskMap[task.id]) {
                  const difference = diff(taskMap[task.id], task);
                  if (difference.length === 0) {
                    // console.log('same');
                  } else {
                    // console.log('different', difference);
                  }
                  taskMap[task.id] = {...taskMap[task.id], ...task};
                } else {
                  taskMap[task.id] = task;
                }
              });
              this.tasksUnfiltered = Object.values(taskMap);
            }
            this.loadingTasks--;
            this.loading = false;
            resolve();
          }
        );
      });
    },
    async taskUpdated(task) { //task) {
      console.log('update task', task);
      const team_id = await this.team_id_promise;
      if (task.team_id !== team_id) {
        console.log('update: task not for this team', task.team_id, team_id, task);
        return;
      }
      const taskIndex = this.tasksUnfiltered.findIndex(t => t.id === task.id);
      if (taskIndex === -1) {
        console.log('could not find task, skipping')
      } else {
        this.resetOriginalValues(task)
        console.log('updating index', taskIndex);
        this.$set(this.tasksUnfiltered, taskIndex, {...task});
      }
    },
    async keyListener(e) {
      if (!this.scannerMode) {
        return;
      }
      const key = e.key;
      console.log('keydown', key, this.buffer);
      if (this.showWeighDialog) {
        // no scan while dialog open
        // console.log('no scan while open', key)
        return;
      }
      let regex = /[A-Za-z0-9:-]/g;
      if (key === 'Enter' && this.buffer.length > 0) {
        const newItem = `${this.buffer}`;
        this.buffer = '';
        this.searchInput = newItem;
        console.log('enter, setting input', newItem);
      }
      if (key.length !== 1 || !regex.test(`${key}`)) {
        console.log('ignoring input', key)
      } else {
        this.buffer = (this.buffer || '') + key;
      }
    },
    getContainer(id) {
      return this.containers.find(c => c.id === id);
    },
    getContainerName(id) {
      const c = this.containers.find(c => c.id === id);
      return c && c.name;
    },
    removeContainerMeasurement(measurements, index) {
      measurements.splice(index, 1);
    },
    weightRules(item) {
      return [
        v => this.getContainer(item.container_id).weight < v || 'weight must be greater than container'
      ];
    },
    getNetWeight(container_id, weight) {
      const container = this.getContainer(container_id);
      if (container && weight !== undefined) {
        return weight - container.weight;
      } else {
        // console.log('cannot compute net weight', {id, weight, container});
      }
    },
    addContainerMeasurement(measurements, containerId) {
      console.log('addContainerMeasurement', containerId);
      measurements.push({
        total_weight: '',
        counts: {},
        container_id: containerId,
        container: this.getContainer(containerId)
      });
    },
    async setSkipped(task, status) {
      console.log('setSkipped', task);
      const {line, measurements} = task.source;
      const payload = {
        from: this.dateFrom,
        to: this.dateTo,
        component_measurement_detail: this.fixNumbers(measurements)
          .map(cm => ({
            container_id: cm.container_id,
            total_weight: Number.parseInt(cm.total_weight),
            counts: Object.fromEntries(Object.entries(cm.counts).map(([k, v]) => ([k, Number.parseInt(v)]))),
            note: cm.note
          })),
        event: this.event,
        skipped: status,  // setSkipped
        line,
      }
      return this.sendPayload(task, payload);
    },
    async save(task) {
      console.log('saving', task);
      const {line, measurements} = task.source;
      const payload = {
        from: this.dateFrom,
        to: this.dateTo,
        component_measurement_detail: this.fixNumbers(measurements)
          .map(cm => ({
            container_id: cm.container_id,
            total_weight: Number.parseInt(cm.total_weight),
            counts: Object.fromEntries(Object.entries(cm.counts).map(([k, v]) => ([k, Number.parseInt(v)]))),
            note: cm.note
          })),
        event: this.event,
        line,
      }
      return this.sendPayload(task, payload)
    },
    async sendPayload(task, payload) {
      const {componentId, mealId} = task.source;
      const response = await api.post(`/v2/component/${componentId}/measurement/${mealId}`, payload);
      console.log('saved', response.data);
      const [cm] = response.data;
      task.source.measurements = cm.component_measurement_detail;
      task.source.skipped = cm.skipped;
      console.log('sending task:update');
      this.$socket.emit('task:update', task);
      this.taskUpdated(task);
      this.searchInput = '';
    },
    hasEdit(task) {
      const diffs = diff(this.fixNumbers(task.source.measurements), task.original_measurements);
      // console.log('diffs', diffs);
      return (diffs.length > 0)
    },
    sumNetWeight(measurements) {
      return measurements.reduce((sum, m) => m.net_weight + sum, 0)
    },
    getDifference(source) {
      return this.sumNetWeight(source.measurements) - source.totalAmount;
    },
    closeDatePicker() {
      this.showDatePicker = false;
      const date = moment(this.datePickerDate);
      const dates = getProductionDays(date);
      console.log('setting dates', dates);
      this.dates = dates;
      return this.fetchData();
    },
    percentOver(source) {
      return (((this.sumNetWeight(source.measurements) / source.totalAmount) - 1) * 100).toFixed(0);
    },
    stopListener() {
      // console.log('stop key listener');
      document.removeEventListener('keydown', this.keyListener, true);
    },
    startListener() {
      // console.log('start key listener');
      document.addEventListener('keydown', this.keyListener, true);
    },
    getCounts(source) {
      const targetCounts = source.count;
      let totalCountsRecorded = 0;
      const actualCounts = source.measurements
        .reduce((sums, m) => {
          Object
            .entries(m.counts)
            .forEach(([k, v]) => {
              sums[k] = sums[k] || 0;
              sums[k] += v;
              totalCountsRecorded += v;
            });
          return sums;
        }, {});
      const difference = Object.fromEntries(
        Object.entries(targetCounts)
          .map(([k, v]) => ([k, v - (actualCounts[k] || 0)])
          )
      );
      return {totalCountsRecorded, targetCounts, actualCounts, difference}
    },
    getShortCounts(source) {
      const entries = Object.entries(this.getCounts(source).difference)
        .filter(([, v]) => v > 0);

      // sort by size
      entries.sort(([e1], [e2]) => sizeSort(e1, e2));
      return Object.fromEntries(entries);
    },
    getOverCounts(source) {
      const entries = Object.entries(this.getCounts(source).difference)
        .filter(([, v]) => v < 0);

      // sort by size
      entries.sort(([e1], [e2]) => sizeSort(e1, e2));
      return Object.fromEntries(entries);
    },
    hasCounts(source) {
      const counts = this.getCounts(source);
      // only say short if some count is entered
      return counts.totalCountsRecorded > 0
    },
    isPartShort(part) {
      return (part.totalAmountMeasured > 0 && part.totalAmount > part.totalAmountMeasured) || part.isShortCount;
    },
    isShortCountTask(source) {
      // only say short if some count is entered
      if (this.hasCounts(source)) {
        // if every difference is negative, no shorts
        return Object.values(this.getCounts(source).difference).some(v => v > 0);
      }
    },
    isShortWeightTask(source) {
      if (this.sumNetWeight(source.measurements) === 0) {
        // a bit of a hack.  no weight recorded assumes this is being audit by count
        return false;
      }
      const weightDifference = this.getDifference(source);
      return weightDifference < 0
    },
    isOverWeightTask(source) {
      return ((this.percentOver(source) > 10) || this.getDifference(source) > 1000);
    },
    isWeightOkTask(source) {
      return !(this.isShortWeightTask(source) || this.isOverWeightTask(source));
    },
    resetOriginalValues(task) {
      (task.source.measurements || []).forEach(m => {
        m.counts = m.counts || {};
        m.counts.medium = m.counts.medium || 0;
        m.counts.small = m.counts.small || 0;
        m.counts.large = m.counts.large || 0
      })
      task.original_measurements = JSON.parse(JSON.stringify(task.source.measurements || []));
    },
    containerHeaders(task) {
      // console.log('headers for', task);
      const targetCounts = task.source.count;
      const actualSizes = Object.keys(targetCounts).sort(sizeSort);
      const counts = actualSizes.map(size => ({value: `counts.${size}`, text: size})); //`${size[0]}`.toUpperCase()}));
      return [
        {value: 'container_id', text: 'Container'},
        ...counts,
        {value: 'total_weight', text: 'Weight on Scale'},
        {value: 'net_weight', text: 'Net Weight'},
        {value: 'action'}
      ];
    },
    sortBySize(vals) {
      return vals.sort(sizeSort);
    },
    getOverAmounts(p, line = undefined) {
      if (line) {
        line = new RegExp(line, 'i');
      }

      const {componentId} = p.task.source;
      const componentCompletionStatus = this.componentCompletionStatus;
      const componentParts = componentCompletionStatus[componentId];
      return Object.values(componentParts)
        .filter(p => !line || line.test(p.line))
        .filter(p => (p.totalAmountMeasured > p.totalAmount) || Object.keys(p.overCounts).length > 0);
    },

    getTotalNet(measurements) {
      return (measurements || []).reduce((sum, m) => sum + m.net_weight, 0);
    },
    exportFilename() {
      return `component-${this.event}-${this.dates.join('-')}`;
    },
  },
  computed: {
    ...mapState(['containers']),
    isWasteMode() {
      return this.event === 'waste'
    },
    isAuditMode() {
      return this.event === 'file'
    },
    task() {
      if (this.tasks.length === 1) {
        return this.tasks[0];
      } else {
        return false;
      }
    },
    tasks() {
      function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }

      let result = this.tasksUnfiltered.slice();
      if (this.searchInput) {
        const escapedInput = escapeRegExp(this.searchInput);
        // console.log('escapedInput',escapedInput);
        const regex = new RegExp(escapedInput, 'i');
        result = result.filter(({
                                  title,
                                  description,
                                  source: {key, rack}
                                }) => regex.test(`${title} ${description} ${key} ${'M' + `${rack}`.padStart(2, '0')}`));
      }
      const teamFilter = this.teamFilter;
      if (Array.isArray(teamFilter) && teamFilter.length > 0) {
        result = result.filter(t => teamFilter.some(team => (t.source.batch || '').startsWith(team)));
      } else if (teamFilter) {
        result = result.filter(t => (t.source.batch || '').startsWith(teamFilter));
      }

      if (this.scannerMode) {
        if (result.length === 1) {
          return result;
        } else {
          console.log('scanner mode, not showing all results');
          return [];
        }
      } else {
        return result.sort((a, b) => a.title.localeCompare(b.title));
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    selectedContainers() {
      return this.containers.filter(c => new RegExp(this.containerFilter, 'i').test(c.name))
    },
    tasksWithoutMeasurement() {
      return this.tasksFiltered.filter(t => !t.source.measurements || t.source.measurements.length === 0);
    },
    tasksWithMeasurement() {
      return this.tasksFiltered.filter(t => t.source.measurements && t.source.measurements.length > 0);
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    mealsReady() {
      return this.mealCompletionStatus
        .filter(m => m.partsRemaining.every(p => p.task.source.line !== 'M'))
      // .filter(m => m.percentComplete === 1);
    },
    mealsSubReady() {
      return this.mealCompletionStatus
        .filter(m => m.partsRemaining.every(p => p.task.source.line !== 'S'))
      // .filter(m => m.percentComplete === 1);
    },
    mealsWithSub() {
      return this.mealCompletionStatus
        .filter(m => m.parts.some(p => p.task.source.line !== 'S'))
      // .filter(m => m.percentComplete === 1);
    },
    mealsNearlyReady() {
      return this.mealCompletionStatus
        .filter(m => m.partsRemaining.some(p => p.task.source.line === 'M'));
      //.filter(m => m.percentComplete < 1); // && m.percentComplete > 0);
    },
    mealsNotStarted() {
      return this.mealCompletionStatus.filter(m => m.percentComplete === 0);
    },
    mealsWithMainShort() {
      const mealsWithMainShort = this.mealCompletionStatus
        .map(m => ({
          ...m,
          partsRemaining: m.partsRemaining.filter(p => p.task.source.line !== 'S')
        }))
        .filter(m => m.partsRemaining.length > 0 && m.partsRemaining.some(this.isPartShort));
      return mealsWithMainShort.map(m => ({
        ...m,
        partsRemaining: m.partsRemaining.map(p => ({...p, overAmounts: this.getOverAmounts(p, '(M|AL)')}))
      }));
    },
    mealsWithSubShort() {
      return this.mealCompletionStatus
        .map(m => ({
          ...m,
          partsRemaining: m.partsRemaining.filter(p => p.task.source.line === 'S')
        }))
        .filter(m => m.partsRemaining.length > 0 && m.partsRemaining.some(this.isPartShort))
        .map(m => ({
          ...m,
          partsRemaining: m.partsRemaining.map(p => ({...p, overAmounts: this.getOverAmounts(p, 'S')}))
        }));
    },
    mealsWithMainSkipped() {
      return this.mealCompletionStatus
        .map(m => ({
          ...m,
          partsSkipped: m.partsSkipped.filter(p => p.task.source.line !== 'S')
        }))
        .filter(m => m.partsSkipped.length > 0);
    },
    mealsWithSubSkipped() {
      return this.mealCompletionStatus
        .map(m => ({
          ...m,
          partsSkipped: m.partsSkipped.filter(p => p.task.source.line === 'S')
        }))
        .filter(m => m.partsSkipped.length > 0);
    },
    componentCompletionStatus() {
      const completeMap = {};
      this.tasksFiltered.forEach(task => {
        const {source} = task;
        if (source.key === 'CC') {
          // skip CC meals
        } else {
          const componentId = source.componentId;
          const mealId = source.mealId;
          const totalAmountMeasured = source.measurements.reduce((sum, m) => sum + m.net_weight, 0);
          const hasWeightMeasurements = source.measurements.length > 0 && totalAmountMeasured > 0;
          const totalAmount = source.totalAmount;
          const weightComplete = totalAmountMeasured >= totalAmount;
          completeMap[componentId] = completeMap[componentId] || {}
          const isShortCount = this.isShortCountTask(source);
          const hasCount = this.hasCounts(source);
          completeMap[componentId][`${mealId}-${source.line}`] = {
            line: source.line,
            complete: (hasCount && !isShortCount) || weightComplete,
            hasWeightMeasurements,
            hasCount,
            totalAmountMeasured,
            totalAmount,
            task,
            isShortCount,
            shortCounts: this.getShortCounts(source),
            overCounts: this.getOverCounts(source)
          };
        }
      });
      return completeMap;
    },
    mealCompletionStatus() {
      const lineMap = {M: 1, AL: 2, S: 3};
      const mealsReady = [];
      const mealIdMap = {};
      const completeMap = {};
      this.tasksFiltered.forEach(task => {
        const {source} = task;
        if (source.key === 'CC') {
          // skip CC meals
        } else {
          const skipped = source.skipped;
          const totalAmountMeasured = source.measurements.reduce((sum, m) => sum + m.net_weight, 0);
          const hasWeightMeasurements = source.measurements.length > 0 && totalAmountMeasured > 0;
          const totalAmount = source.totalAmount;
          const weightComplete = totalAmountMeasured >= totalAmount;
          completeMap[source.mealId] = completeMap[source.mealId] || {}
          const isShortCount = this.isShortCountTask(source);
          const hasCount = this.hasCounts(source);
          completeMap[source.mealId][`${(source.componentId)}-${source.line}`] = {
            complete: skipped || ((hasCount && !isShortCount) || weightComplete),
            skipped,
            hasWeightMeasurements,
            hasCount,
            totalAmountMeasured,
            totalAmount,
            task,
            isShortCount,
            shortCounts: this.getShortCounts(source)
          };
          mealIdMap[source.mealId] = {
            priority: source.priority,
            name: source.mealName,
            key: source.key,
            rack: `M${source.rack}` //'M' + `${source.rack}`.padStart(2, '0')
          };
        }
      })
      Object.keys(completeMap)
        .forEach(mealId => {
          const key = a => `${a.task.description}-${lineMap[a.task.source.line]}-${a.task.source.line}`;
          const parts = Object.values(completeMap[mealId]).sort((a, b) => key(a).localeCompare(key(b)));
          const partsComplete = parts.filter(p => p.complete);
          const partsRemaining = parts.filter(p => !p.complete);
          const partsSkipped = parts.filter(p => p.skipped);
          const isMainMissing = partsRemaining.filter(p => p.task.source.line === 'M').length;
          const isAllergyMissing = partsRemaining.filter(p => p.task.source.line === 'AL' || p.task.source.line === 'S').length;
          mealsReady.push({
            mealId,
            ...mealIdMap[mealId],
            itemCount: parts.length,
            itemsComplete: partsComplete.length,
            itemsLeft: parts.length - partsComplete.length,
            percentComplete: partsComplete.length / parts.length,
            parts,
            partsSkipped,
            partsRemaining,
            isMainMissing,
            isAllergyMissing
          });
        })
      const key = a => `${a.priority}`.padStart(2, '0') + `${a.itemsLeft}`.padStart(2, '0');
      return mealsReady.sort((a, b) => key(a).localeCompare(key(b)));
    },
    teamCount() {
      return this.tasksUnfiltered.map(t => (t.source.batch || '').slice(0, 2))
        .reduce((countMap, team) => {
          countMap[team] = countMap[team] || 0;
          countMap[team]++;
          return countMap;
        }, {});
    },
    teams() {
      return Object.keys(this.teamCount).sort();
    },
    tasksFiltered() {
      const teamFilter = this.teamFilter;
      if (Array.isArray(teamFilter) && teamFilter.length > 0) {
        console.log('team filter', teamFilter);
        return this.tasksUnfiltered.filter(t => teamFilter.some(team => (t.source.batch || '').startsWith(team)));
      } else if (teamFilter) {
        return this.tasksUnfiltered.filter(t => (t.source.batch || '').startsWith(teamFilter));
      } else {
        return this.tasksUnfiltered;
      }
    },
    showSkip() {
      return this.isAuditMode;
    },
    dataAsTable() {
      return this.tasks.map(t => {
        const {measurements, totalAmount, ...remainingSource} = t.source
        return {
          key: t.title,
          component: t.description,
          target_total: totalAmount,
          measured_total: this.sumNetWeight(measurements),
          difference_total: this.getDifference(t.source),
          total_cost_measured_$: ((this.getTotalNet(measurements) * t.source.costPerGram) / 100).toFixed(2),
          cost_per_gram_$: ((t.source.costPerGram) / 100).toFixed(2),
          ...remainingSource
        }
      });
    },
  },
}
</script>
